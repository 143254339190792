import { createRouter, createWebHashHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import type { App } from 'vue'
import { Layout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'

const { t } = useI18n()

export const constantRouterMap: AppRouteRecordRaw[] = [
  {
    path: '/',
    component: Layout,
    redirect: '/Dashboard/WorkHome',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/Dashboard',
    name: 'WorkHome',
    component: Layout,
    meta: {
      title: '工作台',
      icon: 'tdesign:architecture-hui-style',
      hidden: false,
    },
    children: [
      {
        path: 'WorkHome',
        name: 'DashboardWorkHome',
        component: () => import('@/views/Dashboard/WorkHome.vue'),
        meta: {
          title: '首页',
          icon: 'tdesign:architecture-hui-style',
          hidden: false,
        }
      },
      {
        path: 'HomeReport',
        name: 'HomeReport',
        component: () => import('@/views/Dashboard/HomeReport.vue'),
        meta: {
          title: '数据看板',
          icon: 'tdesign:city-13',
          hidden: true,
        }
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  // {
  //   path: '/404',
  //   component: () => import('@/views/Error/404.vue'),
  //   name: 'NoFind',
  //   meta: {
  //     hidden: true,
  //     title: '404',
  //     noTagsView: true
  //   }
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  strict: true,
  routes: constantRouterMap as RouteRecordRaw[],
  scrollBehavior: () => ({ left: 0, top: 0 })
})

export const resetRouter = (): void => {
  const resetWhiteNameList = ['Redirect', 'Login', 'NoFind', 'Root']
  router.getRoutes().forEach((route) => {
    const { name } = route
    if (name && !resetWhiteNameList.includes(name as string)) {
      router.hasRoute(name) && router.removeRoute(name)
    }
  })
}

export const setupRouter = (app: App<Element>) => {
  app.use(router)
}

export default router