import request from '@/axios'
import type { OrderInfoSearchDto, OrderInfoDto, OrderOPPostDto } from '@/api/orderAdmin/orderInfo/types'
import type { GeneralPostDto } from '@/api/base/types'

/**
 * 获取部门订单列表
 */
export const getDepartmentOrderInfoList = (
    data: OrderInfoSearchDto,
    loader?: RequestLoader
): Promise<IResponse<OrderInfoDto[]>> => {
    return request.post({ url: '/SaleLeader/GetDepartmentOrderInfoList', data, loader })
}

/**
 * 获取部门订单详情
 */
export const getDepartmentOrderInfoDetail = (
    orderNumber: string,
    loader?: RequestLoader
): Promise<IResponse<OrderInfoDto>> => {
    return request.get({ url: '/SaleLeader/GetDepartmentOrderInfoDetail', params: { orderNumber }, loader })
}

/**
 * 部门订单取消
 */
export const departmentOrderInfoCancel = (
    data: GeneralPostDto,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.post({ url: '/SaleLeader/DepartmentOrderInfoCancel', data, loader })
}

/**
 * 部门订单审核操作
 */
export const departmentOrderInfoApprovalOP = (
    data: OrderOPPostDto,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.post({ url: '/SaleLeader/DepartmentOrderInfoApprovalOP', data, loader })
}

/**
 * 获取我管理的部门下拉列表
 */
export const getMyDepartmentList = (
    loader?: RequestLoader
): Promise<IResponse<any>> => {
    return request.get({ url: '/SaleLeader/GetMyDepartmentList', loader })
}

/**
 * 获取我部门下员工下拉列表
 */
export const gerMyDepartmentUserList = (
    loader?: RequestLoader
): Promise<IResponse<any>> => {
    return request.get({ url: '/SaleLeader/GerMyDepartmentUserList', loader })
}