/**
 * 日期区间类
 */
export class DateRange {
  start?: string
  end?: string

  constructor(start?: string, end?: string) {
    this.start = start
    this.end = end
  }
}
