import { ElLoading, ElMessage, ElMessageBox, messageType } from 'element-plus'

const loading = (): any => {
  return ElLoading.service({
    lock: true
  })
}

const message = {
  success: (message: string): void => {
    ElMessage.success({ message })
  },
  warning: (message: string): void => {
    ElMessage.warning({ message })
  },
  info: (message: string): void => {
    ElMessage.info({ message })
  },
  error: (message: string): void => {
    ElMessage.error({ message })
  }
}

const alert = (
  message: string,
  {
    title = '提示',
    confirmButtonText = '确定',
    callback,
    type
  }: { title?: string; confirmButtonText?: string; callback?: () => void; type?: messageType } = {}
) => {
  ElMessageBox.alert(message, title, {
    confirmButtonText,
    callback,
    type
  })
}

const confirm = (
  message: string,
  {
    title = '提示',
    confirmButtonText = '确定',
    cancelButtonText = '取消',
    confirmCallback,
    cancelCallback,
    type = 'warning'
  }: {
    title?: string
    confirmButtonText?: string
    cancelButtonText?: string
    confirmCallback?: () => void
    cancelCallback?: () => void
    type?: messageType
  } = {}
) => {
  ElMessageBox.confirm(message, {
    title,
    confirmButtonText,
    cancelButtonText,
    type
  })
    .then(() => {
      if (confirmCallback) {
        confirmCallback()
      }
    })
    .catch(() => {
      if (cancelCallback) {
        cancelCallback()
      }
    })
}

const ui = {
  loading,
  message,
  alert,
  confirm
}

export default ui
