import request from '@/axios'
import type { RegionDto, RegionSearchDto, RegionPostDto } from './types'

/**
 * 获取地区列表
 */
export const getRegionList = (
    data: RegionSearchDto,
    loader?: RequestLoader
): Promise<IResponse<RegionDto[]>> => {
    return request.post({ url: '/SysAdmin/GetRegionList', data, loader })
}

/**
 * 获取地区
 */
export const getRegion = (
    id: number,
    loader?: RequestLoader
): Promise<IResponse<RegionDto>> => {
    return request.get({ url: '/SysAdmin/GetRegion', params: { id }, loader })
}

/**
 * 保存地区
 */
export const saveRegion = (
    data: RegionPostDto,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.post({ url: '/SysAdmin/SaveRegion', data, loader })
}

/**
 * 删除地区
 */
export const deleteRegion = (
    id: number,
    loader?: RequestLoader
): Promise<IResponse<RegionDto>> => {
    return request.get({ url: '/SysAdmin/DeleteRegion', params: { id }, loader })
}

/**
 * (地区列表和地区编辑专用的)获取下拉地区列表
 */
export const getSysSelectItemRegionList = (): Promise<IResponse<OptionItemType[]>> => {
    return request.get({ url: '/SysAdmin/GetSelectItemRegionList' })
}