import type { App } from 'vue'
import * as utils from './index'
import ui from './ui'

export const setupGlobalProperties = (app: App<Element>) => {
  // 过滤器
  const { trim, isEmpty, formatDateTimeFromMoment, formatDate, formatDateTimeForMinute } = utils
  const $filter = {
    trim,
    isEmpty,
    formatDateTimeFromMoment,
    formatDate,
    formatDateTimeForMinute
  }
  // 过滤器
  app.config.globalProperties.$filter = $filter
  // 方法
  app.config.globalProperties.$utils = utils
  // 交互
  app.config.globalProperties.$ui = ui
}
