import request from '@/axios'
import type { QuickSearchDto, ModifyPassPostDto, UploadDto, UploadIconPostDto } from './types'
import type { AccountInfoDto } from '@/api/userAdmin/accountInfo/types'

/**
 * 获取枚举列表
 */
export const getEnumList = (): Promise<IResponse<any>> => {
  return request.get({ url: '/Base/GetEnumList' })
}

/**
 * 快速搜索账号接口
 * @param keyword 关键词
 * @returns <= 6个账号信息
 */
export const QuickSearchAccount = (
  keyword: string,
  loader?: RequestLoader
): Promise<IResponse<QuickSearchDto[]>> => {
  return request.get({ url: '/Base/QuickSearchAccount', params: { keyword }, loader })
}

/**
 * 修改密码
 */
export const modifyPass = (data: ModifyPassPostDto, loader?: RequestLoader): Promise<IResponse> => {
  return request.post({ url: '/Base/ModifyPass', data, loader })
}

/**
 * 上传头像
 */
export const uploadIcon = (data: UploadIconPostDto, loader?: RequestLoader): Promise<IResponse<AccountInfoDto>> => {
  return request.post({ url: '/Base/UploadIcon', data, loader })
}

/**
 * 获取当前用户信息
 */
export const getUserInfo = (
  loader?: RequestLoader
): Promise<IResponse<AccountInfoDto>> => {
  return request.get({ url: '/Base/GetUserInfo', loader })
}

/**
 * 上传图片
 */
export const pictureUpload = (
  data: { file: any; fileUploadType: FileUploadType },
  loader?: RequestLoader
): Promise<IResponse<UploadDto>> => {
  return request.post({
    url: '/Base/PictureUpload',
    headers: {
      'Content-Type': 'multipart/form-data',
      timeout: 60000
    },
    data: { file: data.file, fileUploadType: data.fileUploadType },
    loader
  })
}

/**
 * 获取下拉地区列表
 */
export const getSelectItemRegionList = (): Promise<IResponse<OptionItemType[]>> => {
  return request.get({ url: '/Base/GetSelectItemRegionList' })
}
