import request from '@/axios'
import type { DepartmentInfoDto, DepartmentInfoPostDto, DepartmentInfoSearchDto } from './types'

/**
 * 获取部门列表
 */
export const getDepartmentInfoList = (
  data: DepartmentInfoSearchDto,
  loader?: RequestLoader
): Promise<IResponse<DepartmentInfoDto[]>> => {
  return request.post({ url: '/UserAdmin/GetDepartmentInfoList', data, loader })
}

/**
 * 获取部门下拉
 */
export const getSelectItemDepartmentList = (
  loader?: RequestLoader
): Promise<IResponse<any>> => {
  return request.get({ url: '/UserAdmin/GetSelectItemDepartmentList', loader })
}

/**
 * 获取部门信息
 */
export const getDepartmentInfo = (
  id: number,
  loader?: RequestLoader
): Promise<IResponse<DepartmentInfoDto>> => {
  return request.get({ url: '/UserAdmin/GetDepartmentInfo', params: { id }, loader })
}

/**
 * 保存部门信息
 */
export const departmentInfoSave = (
  data: DepartmentInfoPostDto,
  loader?: RequestLoader
): Promise<IResponse<void>> => {
  return request.post({ url: '/UserAdmin/DepartmentInfoSave', data, loader })
}

/**
 * 清缓存
 */
export const departmentInfoCacheRefresh = (loader?: RequestLoader): Promise<IResponse<void>> => {
  return request.get({ url: '/UserAdmin/DepartmentInfoCacheRefresh', loader })
}
