import request from '@/axios'
import type {
  PromotionInfoDto,
  PromotionInfoSearchDto,
  PromotionInfoPostDto,
  PromotionAssignDto,
  PromotionAssignSearchDto,
  PromotionAssignPostDto
} from './types'
import type { GeneralPostDto } from '@/api/base/types'

/**
 * 获取投放帐户列表
 */
export const getPromotionInfoList = (
  data: PromotionInfoSearchDto,
  loader?: RequestLoader
): Promise<IResponse<PromotionInfoDto[]>> => {
  return request.post({ url: '/PromotionAdmin/GetPromotionInfoList', data, loader })
}

/**
 * 获取投放帐户下拉
 */
export const getSelectItemPromotionInfoList = (loader?: RequestLoader): Promise<IResponse<any>> => {
  return request.get({ url: '/PromotionAdmin/GetSelectItemPromotionInfoList', loader })
}

/**
 * 获取投放帐户
 */
export const getPromotionInfo = (
  id: number,
  loader?: RequestLoader
): Promise<IResponse<PromotionInfoDto>> => {
  return request.get({ url: '/PromotionAdmin/GetPromotionInfo', params: { id }, loader })
}

/**
 * 保存投放帐户
 */
export const savePromotionInfo = (
  data: PromotionInfoPostDto,
  loader?: RequestLoader
): Promise<IResponse> => {
  return request.post({ url: '/PromotionAdmin/SavePromotionInfo', data, loader })
}

/**
 * 获取投放账号分配列表
 */
export const getPromotionAssignList = (
  data: PromotionAssignSearchDto,
  loader?: RequestLoader
): Promise<IResponse<PromotionAssignDto[]>> => {
  return request.post({ url: '/PromotionAdmin/GetPromotionAssignList', data, loader })
}

/**
 * 获取投放账号分配记录
 */
export const getPromotionAssign = (
  id: number,
  loader?: RequestLoader
): Promise<IResponse<PromotionAssignDto>> => {
  return request.get({ url: '/PromotionAdmin/GetPromotionAssign', params: { id }, loader })
}

/**
 * 保存投放账号分配
 */
export const savePromotionAssign = (
  data: PromotionAssignPostDto,
  loader?: RequestLoader
): Promise<IResponse> => {
  return request.post({ url: '/PromotionAdmin/SavePromotionAssign', data, loader })
}

/**
 * 投放账号分配绑定删除
 */
export const promotionAssignDelete = (
  data: GeneralPostDto,
  loader?: RequestLoader
): Promise<IResponse> => {
  return request.post({ url: '/PromotionAdmin/PromotionAssignDelete', data, loader })
}

/**
 *
 * 推广线索导入
 */
export const promotionInfoImport = (data: any, loader?: RequestLoader): Promise<IResponse> => {
  return request.post({
    url: '/PromotionAdmin/PromotionInfoImport',
    headers: {
      'Content-Type': 'multipart/form-data',
      timeout: 60000
    },
    data,
    loader
  })
}
