import request from '@/axios'
import type { MyCustomerInfoSearchDto, MyCustomerInfoPostDto, MyCustomerFollowPostDto, CustomerRepairPostDto } from './types'
import type { CustomerInfoDto, CustomerFollowDto, CustomerFollowSearchDto } from '@/api/customerAdmin/customerInfo/types'
import type { GeneralPostDto } from '@/api/base/types'

/**
 * 获取我的客户列表
 */
export const getMyCustomerList = (
    data: MyCustomerInfoSearchDto,
    loader?: RequestLoader
): Promise<IResponse<CustomerInfoDto[]>> => {
    return request.post({ url: '/SalePerson/GetMyCustomerList', data, loader })
}

/**
 * 获取我的客户详情
 */
export const getMyCustomerDetail = (
    customerNo: string,
    loader?: RequestLoader
): Promise<IResponse<CustomerInfoDto>> => {
    return request.get({ url: '/SalePerson/GetMyCustomerDetail', params: { customerNo }, loader })
}

/**
 * 我的客户添加、修改
 */
export const saveMyCustomer = (
    data: MyCustomerInfoPostDto,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.post({ url: '/SalePerson/SaveMyCustomer', data, loader })
}

/**
 * 我的客户跟进
 */
export const myCustomerFollow = (
    data: MyCustomerFollowPostDto,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.post({ url: '/SalePerson/MyCustomerFollow', data, loader })
}

/**
 * 完善客户手机号码
 */
export const repairPhone = (
    data: CustomerRepairPostDto,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.post({ url: '/SalePerson/RepairPhone', data, loader })
}

/**
 * 完善客户微信号
 */
export const repairWechat = (
    data: CustomerRepairPostDto,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.post({ url: '/SalePerson/RepairWechat', data, loader })
}

/**
 * 获取我的客户跟进列表
 */
export const getMyCustomerFollowList = (
    data: CustomerFollowSearchDto,
    loader?: RequestLoader
): Promise<IResponse<CustomerFollowDto[]>> => {
    return request.post({ url: '/SalePerson/GetMyCustomerFollowList', data, loader })
}

/**
 * 我的(意向)客户解密
 */
export const myCustomerDecrypt = (
    data: GeneralPostDto,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.post({ url: '/SalePerson/MyCustomerDecrypt', data, loader })
}

/**
 * 获取我的工作微信下拉列表
 */
export const getMyWorkDeviceList = (
    loader?: RequestLoader
): Promise<IResponse<any>> => {
    return request.get({ url: '/SalePerson/GetMyWorkDeviceList', loader })
}

/**
 * 重粉查询
 */
export const repeatFansQuery = (
    data: GeneralPostDto,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.post({ url: '/SalePerson/RepeatFansQuery', data, loader })
}