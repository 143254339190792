import { defineStore } from 'pinia'
import { store } from '../index'
import { objMerge } from '@/utils/index'
import { getEnumList, getSelectItemRegionList } from '@/api/base'
import ui from '@/utils/ui'
// API
import { getSysMenuListApi } from '@/api/sysAdmin/sysMenu'
import { getSelectItemDepartmentList } from '@/api/userAdmin/departmentInfo'
import { getGeneraAccountRoleList } from '@/api/userAdmin/accountInfo'
import { getSelectItemPromotionInfoList } from '@/api/promotion/promotionInfo'
import { getMyWorkDeviceList } from '@/api/salePerson/myCustomer'
import { getMyProductShelvesList } from '@/api/salePerson/createOrder'
import { getMyDepartmentList, gerMyDepartmentUserList } from '@/api/saleLeader/departmentOrderInfo'
import { getSysSelectItemRegionList } from '@/api/sysAdmin/region'
import { arrayToNestedObject } from '@/utils'

const remoteFuncMap = {
  SysMenu: getSysMenuListApi,
  DepartmentInfo: getSelectItemDepartmentList,
  GeneraAccountRole: getGeneraAccountRoleList,
  PromotionInfo: getSelectItemPromotionInfoList,
  MyWorkDevice: getMyWorkDeviceList,
  MyProductShelves: getMyProductShelvesList,
  Region: getSelectItemRegionList,
  MyDepartmentList: getMyDepartmentList,
  MyDepartmentUserList: gerMyDepartmentUserList,
  SysRegion: getSysSelectItemRegionList
}

const valueConvertMap = {
  DepartmentInfo: (list) => {
    return arrayToNestedObject(list, (obj, children) => {
      return {
        key: obj.key,
        value: parseInt(obj.value),
        disabled: obj.disabled,
        children
      }
    })
  },
  MyDepartmentList: (list) => {
    return arrayToNestedObject(list, (obj, children) => {
      return {
        key: obj.key,
        value: parseInt(obj.value),
        disabled: obj.disabled,
        children
      }
    })
  },
  MyDepartmentUserList: (list) => {
    return arrayToNestedObject(list, (obj, children) => {
      return {
        key: obj.key,
        value: parseInt(obj.value),
        disabled: obj.disabled,
        children
      }
    })
  },
  MyWorkDevice: (list) => {
    return arrayToNestedObject(list, (obj, children) => {
      return {
        key: obj.key,
        value: parseInt(obj.value),
        disabled: obj.disabled,
        children
      }
    })
  },
  GeneraAccountRole: (list) => {
    return arrayToNestedObject(list, (obj, children) => {
      return {
        key: obj.name,
        value: obj.id,
        disabled: obj.disabled,
        children
      }
    })
  },
  PromotionInfo: (list) => {
    return arrayToNestedObject(list, (obj, children) => {
      return {
        key: obj.key,
        value: parseInt(obj.value),
        disabled: obj.disabled,
        children
      }
    })
  },
  MyProductShelves: (list) => {
    return arrayToNestedObject(list, (obj, children) => {
      return {
        key: obj.name,
        value: obj.id,
        disabled: obj.disabled,
        children
      }
    })
  },
  SysRegion: (list) => {
    return arrayToNestedObject(list, (obj, children) => {
      return {
        key: obj.key,
        value: parseInt(obj.value),
        disabled: obj.disabled,
        children
      }
    })
  }
}

export const useResourceStore = defineStore('resource', {
  state: () => ({
    options: {},
    lang: {},
    rmOptions: {}
  }),
  getters: {},
  actions: {
    /**
     * 初始化选项
     */
    initOptions(): Promise<any> {
      return new Promise((resolve, reject) => {
        try {
          loadAllOptions((data: any) => {
            this.options = data
            resolve(data)
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    /**
     * 数据选项
     */
    getRmOptions(key: RmOptionsType): Promise<any> {
      return new Promise((resolve, reject) => {
        try {
          const func: any = remoteFuncMap[key]
          if (this.rmOptions[key]) {
            resolve(this.rmOptions[key])
          } else if (!func) {
            const message = `getRmOptions -> "${key}" key not found`
            console.log(message)
            ui.message.error(message)
            resolve([])
          } else {
            func()
              .then((resp) => {
                const convertTo = valueConvertMap[key]
                this.rmOptions[key] = convertTo ? convertTo(resp.data) : resp.data
                resolve(this.rmOptions[key])
              })
              .catch((error) => {
                reject(error)
              })
          }
        } catch (error) {
          reject(error)
        }
      })
    }
  }
})

const loadAllOptions = async (callback: any) => {
  const result = {}
  const allOptionsResp = await getEnumList()
  if (allOptionsResp.success) {
    objMerge(result, allOptionsResp.data)
  }
  callback(result)
}

export const useUserStoreWithOut = () => {
  return useResourceStore(store)
}
