import request from '@/axios'
import type { AccountInfoDto, MyMenuRoleDto, LoginPostDto } from './types'

export const loginApi = (data: LoginPostDto): Promise<IResponse<AccountInfoDto>> => {
  return request.post({ url: '/Base/Login', data })
}

export const loginOutApi = (): Promise<IResponse<boolean>> => {
  return request.get({ url: '/Base/Logout' })
}

export const getMyMenuRouteApi = (): Promise<IResponse<MyMenuRoleDto[]>> => {
  return request.get({ url: '/Base/GetMyMenuRoute' })
}