import request from '@/axios'
import type { CreateOrderPostDto, SimpleProductShelvesDto, SimpleProductShelvesProductSaleDto, SimpleFinanceAccountInfoDto } from './types'
import { ProductSaleDto } from '@/api/productAdmin/productSale/types'

/**
 * 我的客户下单
 */
export const myCustomerOrderCreate = (
    data: CreateOrderPostDto,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.post({ url: '/SalePerson/MyCustomerOrderCreate', data, loader })
}

/**
 * 我的订单修改
 */
export const myOrderInfoModify = (
    data: CreateOrderPostDto,
    loader?: RequestLoader
): Promise<IResponse> => {
    return request.post({ url: '/SalePerson/MyOrderInfoModify', data, loader })
}

/**
 * 获取我的货架列表
 */
export const getMyProductShelvesList = (
    loader?: RequestLoader
): Promise<IResponse<SimpleProductShelvesDto[]>> => {
    return request.get({ url: '/SalePerson/GetMyProductShelvesList', loader })
}

/**
 * 获取我的货架商品列表
 */
export const getMyProductShelvesDetailList = (
    productShelvesId: number,
    keyword: string,
    categoryId?: number,
    loader?: RequestLoader
): Promise<IResponse<SimpleProductShelvesProductSaleDto[]>> => {
    return request.get({ url: '/SalePerson/GetMyProductShelvesDetailList', params: { productShelvesId, categoryId, keyword }, loader })
}

/**
 * 获取我的货架商品
 */
export const getMyMyProductShelvesProductSale = (
    productShelvesId: number,
    productSaleId: number,
    loader?: RequestLoader
): Promise<IResponse<ProductSaleDto>> => {
    return request.get({ url: '/SalePerson/GetMyMyProductShelvesProductSale', params: { productShelvesId, productSaleId }, loader })
}

/**
 * 获取我的收款账户列表
 */
export const getMyFinanceAccountList = (
    loader?: RequestLoader
): Promise<IResponse<SimpleFinanceAccountInfoDto[]>> => {
    return request.get({ url: '/SalePerson/GetMyFinanceAccountList', loader })
}