import request from '@/axios'
import type { AccountInfoDto, AccountInfoPostDto, AccountInfoSearchDto } from './types'
import type { AccountRoleDto } from '@/api/sysAdmin/accountRole/types'
import type { GeneralPostDto } from '@/api/base/types'

/**
 * 获取账号信息列表
 */
export const getAccountInfoList = (
  data: AccountInfoSearchDto,
  loader?: RequestLoader
): Promise<IResponse<AccountInfoDto[]>> => {
  return request.post({ url: '/UserAdmin/GetAccountInfoList', data, loader })
}

/**
 * 获取账号信息
 */
export const getAccountInfo = (
  id: number,
  loader?: RequestLoader
): Promise<IResponse<AccountInfoDto>> => {
  return request.get({ url: '/UserAdmin/GetAccountInfo', params: { id }, loader })
}

/**
 * 保存账号信息
 */
export const saveAccountInfo = (
  data: AccountInfoPostDto,
  loader?: RequestLoader
): Promise<IResponse> => {
  return request.post({ url: '/UserAdmin/SaveAccountInfo', data, loader })
}

/**
 * 校验账号是否存在
 */
export const accountCheck = (
  account: string,
  loader?: RequestLoader
): Promise<IResponse<boolean>> => {
  const data = { objectName: account }
  return request.post({ url: '/UserAdmin/AccountCheck', data, loader })
}

/**
 * 获取一般角色列表接口
 */
export const getGeneraAccountRoleList = (
  loader?: RequestLoader
): Promise<IResponse<AccountRoleDto>> => {
  return request.get({ url: '/UserAdmin/GetAccountRoleList', loader })
}

/**
 * 账号重置密码
 */
export const accountInfoResetPass = (
  data: GeneralPostDto,
  loader?: RequestLoader
): Promise<IResponse> => {
  return request.post({ url: '/UserAdmin/AccountInfoResetPass', data, loader })
}