import request from '@/axios'
import type { SysMenuDto, SysMenuPostDto } from './types'

/* 获取管理菜单列表 */
export const getSysMenuListApi = (loader?: RequestLoader): Promise<IResponse<SysMenuDto[]>> => {
  return request.post({ url: '/SysAdmin/GetSysMenuList', loader })
}

/* 获取管理菜单 */
export const getSysMenuApi = (
  id: number,
  loader?: RequestLoader
): Promise<IResponse<SysMenuDto>> => {
  return request.get({ url: '/SysAdmin/GetSysMenu', params: { id }, loader })
}

/* 保存管理菜单 */
export const saveSysMenuApi = (
  data: SysMenuPostDto,
  loader?: RequestLoader
): Promise<IResponse<number>> => {
  return request.post({ url: '/SysAdmin/SaveSysMenu', data, loader })
}

/* 管理菜单缓存刷新 */
export const sysMenuCacheRefresh = (loader?: RequestLoader): Promise<IResponse<void>> => {
  return request.get({ url: '/SysAdmin/SysMenuCacheRefresh', loader })
}
